
<div class="content-section section-1" style="background-color: {{colorSite}};" *ngIf="NoticiaView!='verNoticia' &&NoticiaView!='addTorneo' &&NoticiaView!='addNoticia' &&NoticiaView!='editNoticia' && NoticiaView!='editTorneo'" >

    
    <!---------------------------------------------------------------------------------------------------------------------------------------------
                            SKINS --  ADD NOTICIAS
    --------------------------------------------------------------------------------------------------------------------------------------------->
    <div  Class="news-card" (click)="addNoticia()"
    *ngIf="((verSolo=='noticias'||verSolo=='todo')&&seter=='1')&&expandNoticiaSola==0&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo'&&NoticiaView!='Ligas'">
        <div class="text-center" style = "font-size: 80px; text-shadow: rgba(0, 0, 0, 0.237) 5px 3px 3px; text-align: center; vertical-align: middle;">
            <span class="fa fa-plus-circle"></span>
        </div>
        <div class="card-body" style="text-align: center; font-size: x-large;">
            Add Noticia
        </div>
    </div>    

    <!---------------------------------------------------------------------------------------------------------------------------------------------
                            SKINS --  ADD TORNEOS - LIGAS
    --------------------------------------------------------------------------------------------------------------------------------------------->
    <div  Class="news-card" (click)="addTorneo()"
    *ngIf="((verSolo=='torneos' ||verSolo=='ligas'  )&&seter=='1')&&expandNoticiaSola==0&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo'&&NoticiaView!='Ligas'">
        <div class="text-center" style = "font-size: 80px; text-shadow: rgba(0, 0, 0, 0.237) 5px 3px 3px; text-align: center; vertical-align: middle;">
            <span class="fa fa-plus-circle"></span>
        </div>
        <div class="card-body" style="text-align: center; font-size: x-large;">
            Add Torneo / Liga
        </div>
    </div>    

    
    
    
    
    
    
    <ng-container *ngFor="let noticia of noticias" >
    
        <!---------------------------------------------------------------------------------------------------------------------------------------------
                            SKINS --  NOTICIAS
        --------------------------------------------------------------------------------------------------------------------------------------------->
        
        <!----     SKINS 1--  NOTICIA  --   DEFAULT  --  codigo 1   --------------------------------------------------------------------------------->

        <div  [ngClass]="{ 'news-card'  : (noticia.cod_noticia!=expandNoticia) , 'expanded-card' : (noticia.cod_noticia==expandNoticia && expandNoticiaSola==0) , 'expanded-sola' : (noticia.cod_noticia==expandNoticia && expandNoticiaSola==1)}" 
        [ngStyle]="{'background-color': noticia.color_fondo != null ? noticia.color_fondo : 'blue'}"
        (click)="toggleExpandir(noticia.cod_noticia, 'noticia')" 
        *ngIf="(noticia.tipo_noticia=='noticia'&&noticia.cod_skin==1&&(verSolo=='noticias'||verSolo=='todo')&&expandNoticiaSola==0&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo')||
        (noticia.tipo_noticia=='noticia'&&noticia.cod_skin==1&&(verSolo=='noticias'||verSolo=='todo')&&expandNoticiaSola==1&&noticia.cod_noticia==expandNoticia&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo')">
            <button  class="button-flotante  " style="  font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" 
            (click)="editarNoticia(noticia.cod_noticia, noticia)"  title="Editar Noticia" *ngIf="seter=='1'" > <i class="fa fa-edit"></i> </button>
            <div class="text-center">
                <img  style="max-height: 200px; ;" src="assets/imagen/empresas/{{noticia.cod_empresa}}/noticias/{{noticia.cod_noticia}}/{{noticia.imagen1}}"  alt="{{noticia.titulo}}" 
                onerror="this.onerror=null; this.src='assets/imagen/default/default.png'"
                *ngIf="edicion!=true||(edicion==true&&imagenPrevia1==undefined)">
                <img  style="max-height: 200px; ;" [src]="imagenPrevia1" alt="imagenPrevia1"  *ngIf="edicion==true&&imagenPrevia1!=undefined">
            </div>
            <div class="card-body">
                <h2 [ngStyle]="{'color': noticia.color_titulo != null ? noticia.color_titulo : null, 
                    'font-family': noticia.letra_titulo != null ? noticia.letra_titulo : null,
                    'font-size': noticia.tamano_titulo != null ? noticia.tamano_titulo : null}">
                    {{noticia.titulo}}
                </h2>
                <p [ngStyle]="{'color': noticia.color_otros != null ? noticia.color_otros : null, 
                    'font-family': noticia.letra_otros != null ? noticia.letra_otros : null,
                    'font-size': noticia.tamano_otros != null ? noticia.tamano_otros : null}">
                    {{noticia.texto_corto}} 
                </p>
                <p [ngStyle]="{'color': noticia.color_otros != null ? noticia.color_otros : null, 
                    'font-family': noticia.letra_otros != null ? noticia.letra_otros : null,
                    'font-size': noticia.tamano_otros != null ? noticia.tamano_otros : null}"
                    *ngIf="noticia.cod_noticia==expandNoticia" > 
                    {{noticia.noticia}} 
                </p>
                <div class="text-center" *ngIf="noticia.cod_noticia==expandNoticia" >
                    <button class="btn btn-primary" (click)="noticiaSola1(expandNoticiaSola)" >
                        <span *ngIf="expandNoticiaSola==0"><i class="fa fa-expand"></i>  Agrandar Noticia</span> 
                        <span *ngIf="expandNoticiaSola==1"><i class="fa fa-compress"></i>  Salir de Noticia</span> 
                    
                    </button>
                </div>
            </div>
            <div class="card-footer">
                <p class="card-text" [ngStyle]="{'color': noticia.color_otros != null ? noticia.color_otros : null}"> 
                    <small class="text-muted"> 
                        Última actualización {{noticia.fecha}}
                    </small> 
                </p>
            </div> 
        </div>
        <!--  
        -------     SKINS 2--  NOTICIA  --   1 imagen titulo arriba -- codigo 2     -------------------------------------------------------------------------
        -->
        
        <div  [ngClass]="{ 'news-card'  : (noticia.cod_noticia!=expandNoticia) , 'expanded-card' : (noticia.cod_noticia==expandNoticia && expandNoticiaSola==0) , 'expanded-sola' : (noticia.cod_noticia==expandNoticia && expandNoticiaSola==1)}" 
        [ngStyle]="{'background-color': noticia.color_fondo != null ? noticia.color_fondo : 'blue'}"
        (click)="toggleExpandir(noticia.cod_noticia, 'noticia')" 
        *ngIf="(noticia.tipo_noticia=='noticia'&&noticia.cod_skin==2&&(verSolo=='noticias'||verSolo=='todo')&&expandNoticiaSola==0&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo')||
               (noticia.tipo_noticia=='noticia'&&noticia.cod_skin==2&&(verSolo=='noticias'||verSolo=='todo')&&expandNoticiaSola==1&&noticia.cod_noticia==expandNoticia&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo')">
            <button  class="button-flotante  " style="  font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" 
            (click)="editarNoticia(noticia.cod_noticia, noticia)"  title="Editar Noticia" *ngIf="seter=='1'" > <i class="fa fa-edit"></i> </button>
            
            <div class="card-body">
                <h2 [ngStyle]="{'color': noticia.color_titulo != null ? noticia.color_titulo : null, 
                    'font-family': noticia.letra_titulo != null ? noticia.letra_titulo : null,
                    'font-size': noticia.tamano_titulo != null ? noticia.tamano_titulo : null}">
                    {{noticia.titulo}}
                </h2>
            </div>
            <div class="text-center">
                <img  style="max-height: 200px; ;" src="assets/imagen/empresas/{{noticia.cod_empresa}}/noticias/{{noticia.cod_noticia}}/{{noticia.imagen1}}"  alt="{{noticia.titulo}}" 
                onerror="this.onerror=null; this.src='assets/imagen/default/default.png'"
                *ngIf="edicion!=true||(edicion==true&&imagenPrevia1==undefined)">
                <img  style="max-height: 200px; ;" [src]="imagenPrevia1" alt="imagenPrevia1"  *ngIf="edicion==true&&imagenPrevia1!=undefined">
            </div>
            <div class="card-body">
                
                <p [ngStyle]="{'color': noticia.color_otros != null ? noticia.color_otros : null, 
                    'font-family': noticia.letra_otros != null ? noticia.letra_otros : null,
                    'font-size': noticia.tamano_otros != null ? noticia.tamano_otros : null}">
                    {{noticia.texto_corto}} 
                </p>
                <p [ngStyle]="{'color': noticia.color_otros != null ? noticia.color_otros : null, 
                    'font-family': noticia.letra_otros != null ? noticia.letra_otros : null,
                    'font-size': noticia.tamano_otros != null ? noticia.tamano_otros : null}"
                    *ngIf="noticia.cod_noticia==expandNoticia" > 
                    {{noticia.noticia}} 
                </p>
                <div class="text-center" *ngIf="noticia.cod_noticia==expandNoticia" >
                    <button class="btn btn-primary" (click)="noticiaSola1(expandNoticiaSola)" >
                        <span *ngIf="expandNoticiaSola==0"><i class="fa fa-expand"></i>  Agrandar Noticia</span> 
                        <span *ngIf="expandNoticiaSola==1"><i class="fa fa-compress"></i>  Salir de Noticia</span> 
                    
                    </button>
                </div>
            </div>
            <div class="card-footer">
                <p class="card-text" [ngStyle]="{'color': noticia.color_otros != null ? noticia.color_otros : null}"> 
                    <small class="text-muted"> 
                        Última actualización {{noticia.fecha}}
                    </small> 
                </p>
            </div> 
        </div>

        <!--  
        ----------------------------------------------------------------------------------------------------------------------------------------------
                            SKINS --  TORNEOS
        ----------------------------------------------------------------------------------------------------------------------------------------------
        -->
        <!--  
        -------     SKINS --  TORNEO  --   DEFAULT      ----------------------------------------------------------------------------------------------
        -->
        <div class="news-card" [ngClass]="{'expanded-card': noticia.cod_torneo==expandTorneo}" (click)="TorneoInscripcion(noticia.cod_torneo, noticia)"
        
        *ngIf="noticia.tipo_noticia=='torneo'&&noticia.isliga==0&&(verSolo=='torneos'||verSolo=='todo')&&expandNoticiaSola==0&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo'&&NoticiaView!='Ligas'"   >
            <button  class="button-flotante  " style="  font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" 
            (click)="editarTorneo(noticia.cod_torneo, noticia)"  title="Editar Torneo" *ngIf="seter=='1'" > <i class="fa fa-edit"></i> </button>
            <div class="card-body"  >
                <table style="padding: 0px; border: 0px; width: 100%; background-position: center; background-position-y: center; background-image: url('assets/imagen/{{noticia.imagen1}}'); background-repeat: no-repeat; background-size:contain;  "> 
                    <tr>
                        <td>
                            <table style="width: 100%;">
                            <tr>
                                <td style="width: 15%; background-color: aqua;"> {{noticia.deporte}} </td>
                                <td style="width: 20%; text-align: left; background-color: chocolate;">Torneo</td>
                                <td style="width: 60%; text-align: right; background-color: chocolate;"> {{noticia.torneo_categoria}}</td>
                            </tr>
                            </table>
                        </td>                   
                    </tr>
                    <tr>
                        <td style="text-align: center;"><h2> <b>{{noticia.torneo}}</b></h2> </td>
                    </tr>
                    <tr>
                        <td ><p> {{noticia.descripcion}}</p> </td>
                    </tr>
                    <tr *ngIf="noticia.inscripto==1">
                        <td style="align-content: center; background-color: green;"> Estas anotado </td>
                    </tr>
                    <tr *ngIf="noticia.inscripto==0&&noticia.iniciado==0">
                        <td style="align-content: center; background-color: yellow;"> Inscripcion Abierta </td>
                    </tr>
                    <tr *ngIf="noticia.inscripto==0&&noticia.iniciado==1">
                        <td style="align-content: center; background-color: green;"> Evento iniciado </td>
                    </tr>
                    <tr>
                        
                        <!--<td>
                            <button  class="button-flotante  " style="  font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" 
                            (click)="TorneoInscripcion(noticia.cod_torneo, noticia)"  title="Inscribirme al Torneo"> 
                                <i class="fa fa-pencil"></i> 
                            </button>
                        </td> -->
                    </tr>
                    <tr>
                        <td style="text-align: justify;"> <p>{{noticia.noticia}} </p></td>
                    </tr>
                </table>
            </div>
        <div class="card-footer" >
            <p class="card-text"> <small class="text-muted"> Última actualización {{noticia.fecha}}</small> </p>
        </div> 
        
        </div>
                <!--  
        ----------------------------------------------------------------------------------------------------------------------------------------------
                            SKINS --  LIGAS
        ----------------------------------------------------------------------------------------------------------------------------------------------
        -->
        <!--  
        -------     SKINS --  LIGAS  --   DEFAULT      ----------------------------------------------------------------------------------------------
        -->
        <div class="news-card" [ngClass]="{'expanded-card': noticia.cod_torneo==expandTorneo}" (click)="TorneoInscripcion(noticia.cod_torneo, noticia)"
        
        *ngIf="noticia.tipo_noticia=='torneo'&&noticia.isliga==1&&(verSolo=='ligas'||verSolo=='todo')&&expandNoticiaSola==0&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo'&&NoticiaView!='Torneo'"   >
            <button  class="button-flotante  " style="  font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" 
            (click)="editarTorneo(noticia.cod_torneo, noticia)"  title="Editar Torneo" *ngIf="seter=='1'" > <i class="fa fa-edit"></i> </button>
            <div class="card-body"  >
                <table style="padding: 0px; border: 0px; width: 100%; background-position: center; background-position-y: center; background-image: url('assets/imagen/{{noticia.imagen1}}'); background-repeat: no-repeat; background-size:contain;  "> 
                    <tr>
                        <td>
                            <table style="width: 100%;">
                            <tr>
                                <td style="width: 15%; background-color: aqua;"> {{noticia.deporte}} </td>
                                <td style="width: 20%; text-align: left; background-color: chocolate;">Liga</td>
                                <td style="width: 60%; text-align: right; background-color: chocolate;"> {{noticia.torneo_categoria}}</td>
                            </tr>
                            </table>
                        </td>                   
                    </tr>
                    <tr>
                        <td style="text-align: center;"><h2> <b>{{noticia.torneo}}</b></h2> </td>
                    </tr>
                    <tr>
                        <td ><p> {{noticia.descripcion}}</p> </td>
                    </tr>
                    <tr *ngIf="noticia.inscripto==1">
                        <td style="align-content: center; background-color: green;"> Estas anotado </td>
                    </tr>
                    <tr *ngIf="noticia.inscripto==0&&noticia.iniciado==0">
                        <td style="align-content: center; background-color: yellow;"> Inscripcion Abierta </td>
                    </tr>
                    <tr *ngIf="noticia.inscripto==0&&noticia.iniciado==1">
                        <td style="align-content: center; background-color: green;"> Evento iniciado </td>
                    </tr>
                    <tr>
                        
                        <!--<td>
                            <button  class="button-flotante  " style="  font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" 
                            (click)="TorneoInscripcion(noticia.cod_torneo, noticia)"  title="Inscribirme al Torneo"> 
                                <i class="fa fa-pencil"></i> 
                            </button>
                        </td> -->
                    </tr>
                    <tr>
                        <td style="text-align: justify;"> <p>{{noticia.noticia}} </p></td>
                    </tr>
                </table>
            </div>
        <div class="card-footer" >
            <p class="card-text"> <small class="text-muted"> Última actualización {{noticia.fecha}}</small> </p>
        </div> 
        
        </div>


                        <!--  
        ----------------------------------------------------------------------------------------------------------------------------------------------
                            SKINS --  PARTIDOS
        ----------------------------------------------------------------------------------------------------------------------------------------------
        -->
        <!--  
        -------     SKINS --  PARTIDOS  --   DEFAULT      ----------------------------------------------------------------------------------------------
        -->
        <div class="news-card" style="padding: 0px; background-color: rgba(100, 148, 237, 0.312);"
        *ngIf="noticia.tipo_noticia=='partido'&&verSolo=='todo'&&expandNoticiaSola==0&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo'&&NoticiaView!='Torneo'">
            <div id="{{noticia.cod_partido}}" class="news-card" style="background-color: cornflowerblue; margin-bottom: 0px;" 
            *ngIf="noticia.tipo_noticia=='partido'&&verSolo=='todo'&&expandNoticiaSola==0&&NoticiaView!='editTorneo'&&NoticiaView!='inscribirmeTorneo'&&NoticiaView!='Torneo'"    >
                <div class="card-body" >
                    <table style="padding: 0px; border: 0px; width: 100%; "> 
                        <tr>
                            <td>
                                <table style="width: 100%;">
                                <tr>
                                    <td style="width: 15%; background-color: aqua;"> {{noticia.deporte}} </td>
                                    <td style="width: 20%; text-align: left; background-color: chocolate;" *ngIf="!noticia.isliga">Torneo</td>
                                    <td style="width: 20%; text-align: left; background-color: chocolate;" *ngIf="noticia.isliga">Liga</td>
                                    <td style="width: 60%; text-align: right; background-color: chocolate;"> {{noticia.torneo_categoria}}</td>
                                </tr>
                                </table>
                            </td>                   
                        </tr>
                        <tr class="alto" >
                            <td *ngIf="noticia.titulo == 'Llave 1.'" style="text-align: center;"> Final </td>
                            <td *ngIf="noticia.titulo == 'Llave 2.'" style="text-align: center;"> SemiFinal </td>
                            <td *ngIf="noticia.titulo == 'Llave 4.'" style="text-align: center;"> Cuartos de final </td>
                            <td *ngIf="noticia.titulo == 'Llave 8.'" style="text-align: center;"> Octavos de final </td>
                            <td *ngIf="noticia.titulo != 'Llave 8.'&&noticia.titulo != 'Llave 4.'&&noticia.titulo != 'Llave 2.'&&noticia.titulo != 'Llave 1.'" style="text-align: center;"> {{noticia.titulo}} </td>
                        </tr>
                        <tr class="alto" style="vertical-align: bottom;">
                            <td style="text-align: center;"> <b>{{noticia.texto_corto}}</b> </td>
                        </tr>
                        <tr class="alto" style="vertical-align: top;">
                            <td style="text-align: center;"> <b>{{noticia.noticia}}</b> </td>
                        </tr>
                        
                    
                        <tr>
                            <td>
                                <widgedPartidoChangeFecha [codTorneo]="noticia.cod_torneo" [codPartido]="noticia.cod_partido"></widgedPartidoChangeFecha>
                            </td>
                        </tr>

                        
                    </table>
                </div>
                <div class="card-footer">
                    <table style="padding: 0px; border: 0px; width: 100%;">
                        <tr>
                            <td style="max-width: 50px; min-width: 50px; text-align:center;" > 
                                <img src="../../../assets/imagen/empresas/{{varempresa}}/logo/logo.png" alt="">
                            </td>
                            <td style="width: 100%; text-align: center;">
                                <p class="card-text"> 
                                    <small > {{noticia.torneo}}</small> 
                                </p> 
                            </td>
                            <td style="max-width: 70px; min-width: 70px; text-align:center;" > 
                                <table style="padding: 0px; margin: 0px;">
                                    <tr>
                                        <td>
                                            <small style="color: rgb(221, 27, 20); font-size: x-small;"> 
                                                Power By 
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src="../../../assets/imagen/zoneDeporte/logo/logoZDlargo.png" alt="zonedeporte.com">
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div> 
                
            
            </div>
            <div>
                <button  class="button-flotante-menu1  " style="  font-family: {{buttonFont}}; font-size: {{buttonSize}}; color: {{buttonColor}};" 
                (click)="downloadimg(noticia.cod_partido, noticia.texto_corto+'_'+noticia.noticia)"  title="Download imagen" > 
                    <i class="fa fa-download"></i> 
                </button>
            </div>
            
        </div>    
        <!--  
        ----------------------------------------------------------------------------------------------------------------------------------------------
                            FIN DE SKINS
        ----------------------------------------------------------------------------------------------------------------------------------------------
        -->



        <!--  
        ----------------------------------------------------------------------------------------------------------------------------------------------
                            LLAMADAS A CONTROLERS EXTERNOS
        ----------------------------------------------------------------------------------------------------------------------------------------------
        -->
        
    </ng-container>
</div>
 

<div class=" col-12 " style=" background-color: {{colorSite}};" *ngIf="NoticiaView=='verNoticia'" >
    <!--  VER NOTICIA SOLA -->
    <app-noticia  [varnoticia]="noticiapaso" [varempresa]="varempresa">  </app-noticia>
</div>

<div class="none"  style="background-color: {{colorSite}};" *ngIf="NoticiaView=='editNoticia'">
    <!--  EDITAR NOTICIA  -->
    <app-add-noticias [varempresa]="varempresa" [codNoticiaUpdate]="codNoticiaUpdate" [dataNoticiaUpdate]="noticiapaso"> </app-add-noticias>
</div>

<div class="none"  style="background-color: {{colorSite}};" *ngIf="NoticiaView=='addNoticia'">
    <!--  ADD NOTICIA  -->
    <app-add-noticias [varempresa]="varempresa" [codNoticiaUpdate]='null' > </app-add-noticias>
</div>

<div class="none"  style="background-color: {{colorSite}};" *ngIf="NoticiaView=='editTorneo'">
    <!--  EDITAR TORNEO  -->
    <app-add-torneos [varempresa]="varempresa" [codTorneoUpdate]="codNoticiaUpdate" [dataTorneoUpdate]="noticiapaso"> </app-add-torneos>
</div>

<div class="none"  style="background-color: {{colorSite}};" *ngIf="NoticiaView=='addTorneo'">
    <!--  EDITAR TORNEO  -->
    <app-add-torneos [varempresa]="varempresa" [codTorneoUpdate]='null' > </app-add-torneos>
</div>

<div class="none"  style="background-color: {{colorSite}};" *ngIf="NoticiaView=='inscribirmeTorneo'&&seter!=1&&noticiapaso.iniciado==0">
    <!--  iNSCRIBIRME AL TORNEO  -->
    <app-inscripcion-torneos [varempresa]="varempresa" [codTorneo]="codNoticiaUpdate" [dataTorneo]="noticiapaso" [catDeportes]="catDeportes"> </app-inscripcion-torneos>
</div>

<div class="none"  style="background-color: {{colorSite}};" *ngIf="NoticiaView=='inscribirmeTorneo'&&seter==1&&noticiapaso.iniciado==0">
    <!--  HABILITAR EQUIPO (ADMIN) TORNEO  -->
    <app-habilitar-equipo-torneo [varempresa]="varempresa" [codTorneo]="codNoticiaUpdate" [dataTorneo]="noticiapaso" [catDeportes]="catDeportes"> </app-habilitar-equipo-torneo>
</div>

<div class="none"  style="background-color: {{colorSite}};" *ngIf="NoticiaView=='inscribirmeTorneo'&&seter==1&&noticiapaso.iniciado==1&&noticiapaso.partidos==0">
    <!--  HABILITAR ZONAS (ADMIN) TORNEO  -->
    <app-habilitar-zonas-torneo [varempresa]="varempresa" [codTorneo]="codNoticiaUpdate" [dataTorneo]="noticiapaso" [catDeportes]="catDeportes"> </app-habilitar-zonas-torneo>
</div>

<div class="none"  style="background-color: {{colorSite}};" *ngIf="NoticiaView=='inscribirmeTorneo'&&noticiapaso.iniciado==1&&noticiapaso.partidos==1">
    <!--  VER PARTIDOS Y TABLA GENERAL TORNEO  -->
    <app-torneo-general [varempresa]="varempresa" [codTorneo]="codNoticiaUpdate" [dataTorneo]="noticiapaso" [catDeportes]="catDeportes"> </app-torneo-general>
</div>