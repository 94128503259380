import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { SiteService }  from 'src/app/services/site.service';
import { UserService }  from 'src/app/services/user.service';
import { DataService }  from 'src/app/services/data.service';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css'],
  providers: [SiteService, UserService]
})
export class NoticiasComponent implements OnInit, OnDestroy {
  @Input() varempresa: string;
  @Input() verSolo: string;
  @Input() edicion: boolean;
  @Input() noticiaEdit;
  @Input() imagenPrevia1: any;
  @Input() imagenPrevia2: any;
  @Input() imagenPrevia3: any;
  @Input() seter: any;
  @Input() catDeportes: any;
  

  public colorSite: string;
  public noticias;
  public NoticiaView: string = '';
  public noticiapaso;
  public expandNoticia: number = 0;
  public expandTorneo: number = 0;
  public expandNoticiaSola: number = 0;
  public claseCard: string;
  public viewNav: string = '';
  codNoticiaUpdate:number = null;
  data
  dataServices$: Subscription;

  //errors
  status = ''; message='';
  
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
    private _userService: UserService,
    private dataService: DataService
    ) { 
      //traigo valor de nav del services
      this.dataServices$ = this.dataService.getNoticiasNav().subscribe({
        next: data =>{
          this.data = data;
          //console.log(this.data);
          this.NoticiaView = this.data;
        }
      })
    }

  ngOnInit(): void {
    
    
    this.colorSite = this._siteServices.getColorSite();
    //console.log(this.verSolo);
    let identity = this._userService.getIdentity();
    
    
    let body;
    if (identity == null){
      body={Empresa: this.varempresa};
    }else{
      body={Empresa: this.varempresa, User: identity.cod_user};
    };
    //console.log(body);
    if (this.edicion == true){
      this.noticias = this.noticiaEdit;
      

    }else{
      
      this._siteServices.noticias(body).subscribe(
        response => {
          if(response.status != 'error'){
            
            this.noticias = response;
            //console.log(this.noticias);
            
          }else{
            console.log('response.message');
          }
        },
        error => {
          console.log(error);
        } 
      )
    } 
  }

  ngOnDestroy(): void {
    this.dataServices$.unsubscribe();
  }
  salir(){
    // notociaView data envio
    //this._siteServices.itemNoticiasNav$.next('nada check');

  }

  downloadimg(id,nombre:string){
    
    html2canvas(document.getElementById(id)).then(function(canvas) {
      
        // Convert the canvas to blob
        canvas.toBlob(function(blob){
            // To download directly on browser default 'downloads' location
            let link = document.createElement("a");
            
            link.download = nombre+"png";
            link.href = URL.createObjectURL(blob);
            link.click();
            //console.log(nombre+'png');
            // To save manually somewhere in file explorer
            //window.save  saveAs(blob, 'image.png');

        },'image/png', 0);
    });
  }

  toggleExpandir(noticia: number, tipo:string) {
    if(tipo == 'noticia'){
      if ((this.expandNoticia == 0)||(this.expandNoticia != noticia)){
        this.expandNoticia = noticia;
        this.expandTorneo = 0;
      }else{
        this.expandNoticia = 0;
      }
    }
    if (this.expandNoticiaSola == 1){
      this.expandNoticia = noticia;
    }
    //console.log("pasotoggle")
  }

  buscarclase() {
    
    this.claseCard = "expanded-sola";
  }

  noticiaSola1(expandNoticiaSola:number) {
      if (this.expandNoticiaSola == 0) {
      this.expandNoticiaSola = 1
      //console.log('paso noti0 ')
    }else{
      this.expandNoticiaSola = 0
      //console.log('paso noti1')
    }
    //console.log(this.expandNoticiaSola);
    
  }  


  noticiaSola(noticia:any) {
    this.NoticiaView='verNoticia';
    this.noticiapaso=noticia;
    
  }  
  editarNoticia(codigo, noticia:any){
    //console.log('dispara con '+codigo );
    this.NoticiaView='editNoticia';
    this.codNoticiaUpdate=codigo;
    this.noticiapaso=noticia;
  }
  addNoticia(){
    //console.log('dispara con '+codigo );
    this.NoticiaView='addNoticia';
  }
  addTorneo(){
    //console.log('dispara con '+codigo );
    this.NoticiaView='addTorneo';
  }
  editarTorneo(codigo, noticia:any){
    //console.log('dispara con '+codigo );
    this.NoticiaView='editTorneo';
    this.codNoticiaUpdate=codigo;
    this.noticiapaso=noticia;
  }
  TorneoInscripcion(codigo, noticia:any){
    this.NoticiaView='inscribirmeTorneo';
    this.codNoticiaUpdate=codigo;
    this.noticiapaso=noticia;
  }
}
 