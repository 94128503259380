import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SiteService } from 'src/app/services/site.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-ligas',
  templateUrl: './zoneDeporteInicio.component.html',
  styleUrls: ['./zoneDeporteInicio.component.css'],
  providers: [SiteService]
})
export class ZoneDeporteInicioComponent implements OnInit {
public movil: boolean;
  constructor( private _siteServices: SiteService,
              private _route: ActivatedRoute,
              private _router: Router,
              @Inject(DOCUMENT) document: any
  )  { }

  ngOnInit(): void {
    if (localStorage.getItem('navi') == 'N'){
      this.movil = false;
    } else {
      this.movil = true;
    }
    let body = {'domine': document.location.href}
    this._siteServices.loadDomineSites(body).subscribe(
      response => {
        if(response.status != 'error'){
          if (response[0].codigo != 0){
            console.log('https'+response[0].domineSearch+'loadclientsite/'+ response[0].codigo);
            window.location.href = 'https'+response[0].domineSearch+'loadclientsite/'+ response[0].codigo;
          }
        }else{
          console.log(response);
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

}
