import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';
import { TorneosService } from 'src/app/services/torneos.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { exit } from 'process';
import { Time } from '@angular/common';
import { Timestamp } from 'rxjs';
import { isNull } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'widgedPartidoChangeFecha',
  templateUrl: './widgedPartidoChangeFecha.component.html',
  styleUrls: ['./widgedPartidoChangeFecha.component.css'],
  providers: [SiteService, TorneosService]
}) 

export class widgedPartidoChangeFechaComponent implements OnInit, OnDestroy  {
  @Input() codTorneo: number;
  @Input() codPartido: number;
  public seter=0;
  public movil: boolean;
  public datosPartido;
  public fechahora: Date;
  public fecha: Date;
  public hora: Time;
  public strfecha: string;
  public strhora: string;
  public token;
  public edit:boolean = false;
  public editPuntos:boolean = false;
  public menuEdit:boolean = false;
  public finalizado: boolean;
  public iniciado: boolean;
  public ganador: number;
  public contadorrecarga: number;
  public datosConfigPuntaje;
  public setEditando: number;
  destroy = false;
  public configGame=0;
  public configSet=0;
  public taeGameDif=0;
  public taeGameOro=0;
  public taeNoSet=false;
  public taeSetDif=0;
  public taeSetOro=0;
  public puntoOro=false;
  public puntoDif=false;
  public setDifDos = false;
  public salirError = false;
  public message = '';
  public partidoFinalizado= false;
  public guardarProcesando = false;
  public equipoGanador = 0;

  constructor( private _siteServices: SiteService,
              private _torneosService: TorneosService,
              private _route: ActivatedRoute,
              private _router: Router
  )  { }

  ngOnInit(): void {
    this.token =  this._siteServices.getToken();
    this.seter = Number(localStorage.getItem('seter'));
    if (localStorage.getItem('navi') == 'N'){
      this.movil = false;
    } else {
      this.movil = true;
    }
    
    if (this.codTorneo){
      if (this.codPartido){
        // llamo al service
        this.recargarPartido();
      }
    }
  }
  verMenuEdit(){
    if (this.menuEdit) {
      this.menuEdit = false;
    }else{
      if (!this.edit && !this.editPuntos){
        this.menuEdit = true;
      }
      
    }
  }
  editar(){
    this.edit = true;
    this.verMenuEdit();
  }
  cargarPuntos(){
    this.editPuntos = true;
    this.verMenuEdit();
    // traer la configuracion del puntaje
    //console.log(this.datosPartido);
    let body = {'cod_torneo':this.codTorneo};
    this._torneosService.torneoGetConfigPuntaje(body,this.token).subscribe(
      response => {
        if(response.status != 'error'){
          this.datosConfigPuntaje = response;    
          //console.log(this.datosConfigPuntaje);
          this.setEditando = 1;
          
          this.configGame=0;
          this.configSet=0;
          this.taeGameDif=0;
          this.taeGameOro=0;
          this.taeNoSet=false;
          this.taeSetDif=0;
          this.taeSetOro=0;
          this.puntoOro=false;
          this.puntoDif=false;
          this.setDifDos = false;


          for (let i=0; i<this.datosConfigPuntaje.length; i++){
            if (this.datosConfigPuntaje[i].cod_item_juego == 1){
              this.configSet=this.datosConfigPuntaje[i].value_int;
            }
            if (this.datosConfigPuntaje[i].cod_item_juego == 2){
              this.configGame=this.datosConfigPuntaje[i].value_int;
            }
            if (this.datosConfigPuntaje[i].cod_item_juego == 3){
              this.setDifDos=true;
            }
            if (this.datosConfigPuntaje[i].cod_item_juego == 4){
              this.taeNoSet=true;
            }
            if (this.datosConfigPuntaje[i].cod_item_juego == 5){
              this.taeGameOro=this.datosConfigPuntaje[i].value_int;
            }
            if (this.datosConfigPuntaje[i].cod_item_juego == 6){
              this.taeSetOro=this.datosConfigPuntaje[i].value_int;
            }
            if (this.datosConfigPuntaje[i].cod_item_juego == 7){
              this.puntoOro=true;
            }
            if (this.datosConfigPuntaje[i].cod_item_juego == 8){
              this.puntoDif=true;
            }
            if (this.datosConfigPuntaje[i].cod_item_juego == 9){
              this.taeSetDif=this.datosConfigPuntaje[i].value_int;
            }
            if (this.datosConfigPuntaje[i].cod_item_juego == 10){
              this.taeGameDif=this.datosConfigPuntaje[i].value_int;
            }
          }
        }else{
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

  iniciarPartido(){
    // Inicio el partido y creo el primer set

    let body = {'cod_torneo':this.codTorneo, 'cod_partido':this.codPartido};
    this._torneosService.torneoIniciarPartido(body,this.token).subscribe(
      response => {
        if(response.status != 'error'){
          this.recargarPartido();
        }else{
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

  verSet(set: number){
    this.setEditando = set;
  }

  ngOnDestroy(){
    this.destroy = true;
  }

  recargarPartido(){
    let body = {'cod_torneo':this.codTorneo, 'cod_partido':this.codPartido};
    this._torneosService.torneoPuntosPartido(body,'').subscribe(
      response => {
        if(response.status != 'error'){
          this.datosPartido = response;
          if (this.datosPartido[0].iniciado == 1){
            this.iniciado = true;
          }else{
            this.iniciado = false;
          }
          //console.log(this.datosPartido);
          if (!this.iniciado){
            // si no inicio el partido
            //aca coloco los datos de la fecha
            this.fechahora = this.datosPartido[0].fecha_inicio;
            if (this.fechahora){
              //console.log(this.fechahora);
              //console.log(this.fechahora.toString().substring(0,10));
              this.strfecha = this.fechahora.toString().substring(0,10);
              this.strhora = this.fechahora.toString().substring(11,16);
              
              //console.log(this.strfecha);
              //console.log(this.strhora);
              
              //
            }else{
              this.fecha = new Date();
              //console.log(this.fecha);
              this.strfecha = `${this.fecha.getFullYear()}-${this.fecha.getMonth() + 1}-${this.fecha.getDate()}`;
              //console.log(this.strfecha);
              this.strhora = '12:00';
              
            } 
            //console.log(this.fecha);
          }else{
            //si ya inicio el partido y existe un set creado
          
            let gana1 = 0; let gana2 = 0;
            this.finalizado = true;
            this.ganador = 0;
            for (let i = 0; i<this.datosPartido.length; i++){
              if (this.datosPartido[i].finalizado == false){
                this.finalizado = false;
                this.ganador = 0;

              }else{
                if (this.datosPartido[i].gamesEquipo1 > this.datosPartido[i].gamesEquipo2){gana1++}else{gana2++}
              }
            }
            if (this.finalizado == true) {
              if(gana1 > gana2){this.ganador = 1;}else{this.ganador = 2;}
            }else{
              this.verificar();
            }
          }
        }else{
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      } 
    )
    
  }
  tratarFecha(fechain){
    this.strfecha = fechain;
    //console.log(fechain);
  }
  tratarHora(horain){
    this.strhora = horain;
    //console.log('entro a tratar hora '+horain);
  }
  salir(){
    this.editPuntos = false;
    this.edit = false;
    this.verMenuEdit();
    this.recargarPartido();
  }
  onSubmit(itemForm){
    let body = {'cod_torneo':this.codTorneo, 'cod_partido':this.codPartido, 'fecha_inicio':this.strfecha+' '+this.strhora};
    //console.log(body);
    this._torneosService.torneoFechaPartido(body,this.token).subscribe(
      response => {
        if(response.status != 'error'){
          
          //console.log(response);
          this.edit=false;
          this.verMenuEdit();
          this.recargarPartido();
        }else{
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

  addSet(){
    //verificar el maximo set creado
    let creado = this.datosPartido[this.datosPartido.length-1].NroSet;
    //console.log('set creados: '+creado);
    //comparar con la configuracion
    //console.log('set de partido: '+this.configSet);
    if  (   (creado < this.configSet) || 
            ( (creado == this.configSet) &&
              (!this.taeNoSet && ((this.taeSetDif > 0) || (this.taeSetOro > 0) ))
            )
        ){
          this.datosPartido[creado-1].finalizado = 1;
          this.datosPartido[creado-1].pointEquipo1 = 0;
          this.datosPartido[creado-1].pointEquipo2 = 0;
          this.datosPartido.push(
            {
              "status": "ok",
              "equipo1": this.datosPartido[creado-1].equipo1,
              "equipo2": this.datosPartido[creado-1].equipo2,
              "iniciado": 1,
              "fecha_inicio": null,
              "NroSet": this.datosPartido[creado-1].NroSet+1,
              "gamesEquipo1": 0,
              "gamesEquipo2": 0,
              "finalizado": 1,
              "teabrekEquipo1": 0,
              "teabrekEquipo2": 0,
              "pointEquipo1": 0,
              "pointEquipo2": 0,
              "pointDifEquipo1": 0,
              "pointDifEquipo2": 0
            }
          );
    } 
    //console.log(this.datosPartido);
  }


  addGame(equipo: number, tipo:string, accion:string){
    //equipo (1,2), tipo(game, tae), accion(+,-)
    if (tipo == 'game'){
      this.datosPartido[this.setEditando-1].teabrekEquipo1 = 0;
      this.datosPartido[this.setEditando-1].teabrekEquipo2 = 0;
      this.datosPartido[this.setEditando-1].pointEquipo1 = 0;
      this.datosPartido[this.setEditando-1].pointEquipo2 = 0;
      if (equipo == 1 ){
        if (accion == '+'){
          if (  ( (this.datosPartido[this.setEditando-1].gamesEquipo1 == this.configGame) && 
                  (this.datosPartido[this.setEditando-1].gamesEquipo2 <= this.configGame)&&
                  (this.setEditando <= this.configSet)
                )||
                ( (this.datosPartido[this.setEditando-1].gamesEquipo1 < this.configGame)&&
                  (this.setEditando <= this.configSet)
                )
              ){
            this.datosPartido[this.setEditando-1].gamesEquipo1 = this.datosPartido[this.setEditando-1].gamesEquipo1+1;
          }else{
            if  (   (this.datosPartido[this.setEditando-1].gamesEquipo1 == 0) && 
                    (this.datosPartido[this.setEditando-1].gamesEquipo2 == 0) &&
                    (this.setEditando > this.configSet) &&
                    (!this.taeNoSet && ((this.taeSetDif > 0) || (this.taeSetOro > 0) ))
                ){
              this.datosPartido[this.setEditando-1].gamesEquipo1 = this.datosPartido[this.setEditando-1].gamesEquipo1+1;
            }
          }
        }
        if (accion == '-'){
          if (this.datosPartido[this.setEditando-1].gamesEquipo1 > 0){
            this.datosPartido[this.setEditando-1].gamesEquipo1 = this.datosPartido[this.setEditando-1].gamesEquipo1-1;
          }
        }
      }
      if (equipo == 2 ){
        if (accion == '+'){
          if (  ( (this.datosPartido[this.setEditando-1].gamesEquipo2 == this.configGame) && 
                  (this.datosPartido[this.setEditando-1].gamesEquipo1 <= this.configGame) &&
                  (this.setEditando <= this.configSet)
                )||
                ( (this.datosPartido[this.setEditando-1].gamesEquipo2 < this.configGame) &&
                  (this.setEditando <= this.configSet)
                )
              ){
            this.datosPartido[this.setEditando-1].gamesEquipo2 = this.datosPartido[this.setEditando-1].gamesEquipo2+1;
          }else{
            if  (   (this.datosPartido[this.setEditando-1].gamesEquipo2 == 0) && 
                    (this.datosPartido[this.setEditando-1].gamesEquipo1 == 0) &&
                    (this.setEditando > this.configSet) &&
                    (!this.taeNoSet && ((this.taeSetDif > 0) || (this.taeSetOro > 0) ))
                ){
              this.datosPartido[this.setEditando-1].gamesEquipo2 = this.datosPartido[this.setEditando-1].gamesEquipo2+1;
            }
          }
        }
        if (accion == '-'){
          if (this.datosPartido[this.setEditando-1].gamesEquipo2 > 0){
            this.datosPartido[this.setEditando-1].gamesEquipo2 = this.datosPartido[this.setEditando-1].gamesEquipo2-1;
          }
        }
        
      }
    }
    if (tipo == 'tae'){
      this.datosPartido[this.setEditando-1].pointEquipo1 = 0;
      this.datosPartido[this.setEditando-1].pointEquipo2 = 0;
      if (!this.taeNoSet&&( this.taeGameDif > 0 || this.taeGameOro > 0 )){
        if (equipo == 1 ){
          if (accion == '+'){
            if (this.setEditando <= this.configSet){
              if  (   
                    (  
                      ( (this.datosPartido[this.setEditando-1].gamesEquipo1 == this.configGame) && 
                        (this.datosPartido[this.setEditando-1].gamesEquipo2 == this.configGame+1)
                      )||
                      ( (this.datosPartido[this.setEditando-1].gamesEquipo1 == this.configGame+1) && 
                        (this.datosPartido[this.setEditando-1].gamesEquipo2 == this.configGame)
                      )
                    )&&
                    (
                      ( (this.datosPartido[this.setEditando-1].teabrekEquipo1 < this.taeGameOro-1) &&
                        (this.datosPartido[this.setEditando-1].teabrekEquipo2 <= this.taeGameOro)
                      )||
                      ( (this.datosPartido[this.setEditando-1].teabrekEquipo1 < this.taeGameOro) &&
                        (this.datosPartido[this.setEditando-1].teabrekEquipo2 < this.taeGameOro) &&
                        (this.datosPartido[this.setEditando-1].gamesEquipo1 == this.configGame+1)
                      )||
                      (
                        (this.datosPartido[this.setEditando-1].teabrekEquipo1 < this.taeGameDif)                       
                      )
                      ||
                      (
                        (this.datosPartido[this.setEditando-1].teabrekEquipo1 >= this.taeGameDif) &&
                        (this.datosPartido[this.setEditando-1].teabrekEquipo2 >= this.datosPartido[this.setEditando-1].teabrekEquipo1-1)&&
                        (this.datosPartido[this.setEditando-1].gamesEquipo1 == this.configGame+1)&&
                        (this.taeGameDif > 0)                    
                      )
                    )                
                  ){
                this.datosPartido[this.setEditando-1].teabrekEquipo1 = this.datosPartido[this.setEditando-1].teabrekEquipo1+1;
              }
            }else{
              if (
                    (this.setEditando > this.configSet) &&
                    (!this.taeNoSet && ((this.taeSetDif > 0) || (this.taeSetOro > 0) ))
                  ){
                    if  (   
                          (  
                            ( (this.datosPartido[this.setEditando-1].gamesEquipo1 == 1) && 
                              (this.datosPartido[this.setEditando-1].gamesEquipo2 == 0)
                            )||
                            ( (this.datosPartido[this.setEditando-1].gamesEquipo1 == 0) && 
                              (this.datosPartido[this.setEditando-1].gamesEquipo2 == 1)
                            )
                          )&&
                          (
                            ( (this.datosPartido[this.setEditando-1].teabrekEquipo1 < this.taeSetOro-1) &&
                              (this.datosPartido[this.setEditando-1].teabrekEquipo2 <= this.taeSetOro)
                            )||
                            ( (this.datosPartido[this.setEditando-1].teabrekEquipo1 < this.taeSetOro) &&
                              (this.datosPartido[this.setEditando-1].teabrekEquipo2 < this.taeSetOro) &&
                              (this.datosPartido[this.setEditando-1].gamesEquipo1 == 1)
                            )||
                            (
                              (this.datosPartido[this.setEditando-1].teabrekEquipo1 < this.taeSetDif)                       
                            )
                            ||
                            (
                              (this.datosPartido[this.setEditando-1].teabrekEquipo1 >= this.taeSetDif) &&
                              (this.datosPartido[this.setEditando-1].teabrekEquipo2 >= this.datosPartido[this.setEditando-1].teabrekEquipo1-1)&&
                              (this.datosPartido[this.setEditando-1].gamesEquipo1 == 1)&&
                              (this.taeSetDif > 0)                    
                            )
                          )                
                        ){
                      this.datosPartido[this.setEditando-1].teabrekEquipo1 = this.datosPartido[this.setEditando-1].teabrekEquipo1+1;
                    }
                  }
            }  
          }
          if (accion == '-'){
            if (this.datosPartido[this.setEditando-1].teabrekEquipo1 > 0){
              this.datosPartido[this.setEditando-1].teabrekEquipo1 = this.datosPartido[this.setEditando-1].teabrekEquipo1-1;
            }
          }
        }
        if (equipo == 2 ){
          if (accion == '+'){
            if (this.setEditando <= this.configSet){
              if  (   
                    (  
                      ( (this.datosPartido[this.setEditando-1].gamesEquipo2 == this.configGame) && 
                        (this.datosPartido[this.setEditando-1].gamesEquipo1 == this.configGame+1)
                      )||
                      ( (this.datosPartido[this.setEditando-1].gamesEquipo2 == this.configGame+1) && 
                        (this.datosPartido[this.setEditando-1].gamesEquipo1 == this.configGame)
                      )
                    )&&
                    (
                      ( (this.datosPartido[this.setEditando-1].teabrekEquipo2 < this.taeGameOro-1) &&
                        (this.datosPartido[this.setEditando-1].teabrekEquipo1 <= this.taeGameOro)
                      )||
                      ( (this.datosPartido[this.setEditando-1].teabrekEquipo2 < this.taeGameOro) &&
                        (this.datosPartido[this.setEditando-1].teabrekEquipo1 < this.taeGameOro) &&
                        (this.datosPartido[this.setEditando-1].gamesEquipo2 == this.configGame+1)
                      )||
                      (
                        (this.datosPartido[this.setEditando-1].teabrekEquipo2 < this.taeGameDif)                       
                      )
                      ||
                      (
                        (this.datosPartido[this.setEditando-1].teabrekEquipo2 >= this.taeGameDif) &&
                        (this.datosPartido[this.setEditando-1].teabrekEquipo1 >= this.datosPartido[this.setEditando-1].teabrekEquipo2-1)&&
                        (this.datosPartido[this.setEditando-1].gamesEquipo2 == this.configGame+1) &&
                        (this.taeGameDif > 0)
                      )
                    )                
                  ){
                this.datosPartido[this.setEditando-1].teabrekEquipo2 = this.datosPartido[this.setEditando-1].teabrekEquipo2+1;
              }
            }else{
              if (
                    (this.setEditando > this.configSet) &&
                    (!this.taeNoSet && ((this.taeSetDif > 0) || (this.taeSetOro > 0) ))
                  ){
                    if  (   
                          (  
                            ( (this.datosPartido[this.setEditando-1].gamesEquipo2 == 1) && 
                              (this.datosPartido[this.setEditando-1].gamesEquipo1 == 0)
                            )||
                            ( (this.datosPartido[this.setEditando-1].gamesEquipo2 == 0) && 
                              (this.datosPartido[this.setEditando-1].gamesEquipo1 == 1)
                            )
                          )&&
                          (
                            ( (this.datosPartido[this.setEditando-1].teabrekEquipo2 < this.taeSetOro-1) &&
                              (this.datosPartido[this.setEditando-1].teabrekEquipo1 <= this.taeSetOro)
                            )||
                            ( (this.datosPartido[this.setEditando-1].teabrekEquipo2 < this.taeSetOro) &&
                              (this.datosPartido[this.setEditando-1].teabrekEquipo1 < this.taeSetOro) &&
                              (this.datosPartido[this.setEditando-1].gamesEquipo2 == 1)
                            )||
                            (
                              (this.datosPartido[this.setEditando-1].teabrekEquipo2 < this.taeSetDif)                       
                            )
                            ||
                            (
                              (this.datosPartido[this.setEditando-1].teabrekEquipo2 >= this.taeSetDif) &&
                              (this.datosPartido[this.setEditando-1].teabrekEquipo1 >= this.datosPartido[this.setEditando-1].teabrekEquipo2-1)&&
                              (this.datosPartido[this.setEditando-1].gamesEquipo2 == 1)&&
                              (this.taeSetDif > 0)                    
                            )
                          )                
                        ){
                      this.datosPartido[this.setEditando-1].teabrekEquipo2 = this.datosPartido[this.setEditando-1].teabrekEquipo2+1;
                    }
                  }
            } 
          }
          if (accion == '-'){
            if (this.datosPartido[this.setEditando-1].teabrekEquipo2 > 0){
              this.datosPartido[this.setEditando-1].teabrekEquipo2 = this.datosPartido[this.setEditando-1].teabrekEquipo2-1;
            }
          }
        }
      }
    }
  }

  guardarPartido(){
    this.guardarProcesando = true;
    this.salirError = false;
    this.message = '';
    this.partidoFinalizado = false;
    let ganaSetsEquipo1 = 0; 
    let ganaSetsEquipo2 = 0;
    let necesitanGanarSets = 0;
    if (this.configSet == 1){
      necesitanGanarSets = 1;
    }else{
      necesitanGanarSets = 2;
    }
    
    /* 
  //public configGame=0;
  //configSet=0;
  //taeGameDif=0;
  //taeGameOro=0;
  //taeNoSet=false;
  taeSetDif=0;
  taeSetOro=0;
  //puntoOro=false;
  //puntoDif=false;
  //setDifDos = false;
    */



    for (let i = 0; i < this.datosPartido.length; i++){
      if (this.salirError == false){
        //verifico cada set
        //console.log('verificando el set Nro: '+this.datosPartido[i].NroSet);
        
        // si el partido finalizo eliminar set sobrantes
        if (this.partidoFinalizado == true){
          //console.log('eliminar el set '+ this.datosPartido[i].NroSet);
          this.datosPartido.splice(i,1);

        }else{

          // si esta dentro de los set de partido o es un desempate
          if (this.datosPartido[i].NroSet <= this.configSet){
            if (this.taeNoSet){
              //console.log('gana el primero en llegar al game maximo');
              // aca verifico si alguno llego (no hay taebreak)

            }else{
              if (this.setDifDos){
                //console.log('verifico si gana el set por mas de dos con el max game');
                if  ( ( (this.datosPartido[i].gamesEquipo1 == this.configGame)&&
                        (this.datosPartido[i].gamesEquipo2 < this.configGame-1)
                      )||
                      ( (this.datosPartido[i].gamesEquipo1 == this.configGame+1)&&
                        (this.datosPartido[i].gamesEquipo2 == this.configGame-1)
                      ) 
                ){ 
                  //console.log('gana el equipo 1 ');
                  ganaSetsEquipo1++;
                  
                }else{
                  if  ( ( (this.datosPartido[i].gamesEquipo2 == this.configGame)&&
                          (this.datosPartido[i].gamesEquipo1 < this.configGame-1)
                        )||
                        ( (this.datosPartido[i].gamesEquipo2 == this.configGame+1)&&
                          (this.datosPartido[i].gamesEquipo1 == this.configGame-1)
                        ) 
                  ){
                    //console.log('gana el equipo 2 ');
                    ganaSetsEquipo2++;
                    
                  }else{
                    if (this.taeGameOro > 0){
                      //console.log('verifico si gana el set por Taebreak punto de oro');
                      if  ( ( (this.datosPartido[i].gamesEquipo1 == this.configGame+1)&&
                              (this.datosPartido[i].gamesEquipo2 == this.configGame)
                            )&&
                            ( (this.datosPartido[i].teabrekEquipo1 == this.taeGameOro)&&
                              (this.datosPartido[i].teabrekEquipo2 < this.taeGameOro)
                            ) 
                      ){ 
                        //console.log('gana el equipo 1 ');
                        ganaSetsEquipo1++;
                        
                      }else{
                        if  ( ( (this.datosPartido[i].gamesEquipo2 == this.configGame+1)&&
                                (this.datosPartido[i].gamesEquipo1 == this.configGame)
                              )&&
                              ( (this.datosPartido[i].teabrekEquipo2 == this.taeGameOro)&&
                                (this.datosPartido[i].teabrekEquipo1 < this.taeGameOro)
                              ) 
                        ){ 
                          //console.log('gana el equipo 2 ');
                          ganaSetsEquipo2++;
                          
                        }
                      }
                    }else{
                      if (this.taeGameDif > 0){
                        //console.log('verifico si gana el set por Taebreak con diferencia de dos');
                        if  ( ( (this.datosPartido[i].gamesEquipo1 == this.configGame+1)&&
                                (this.datosPartido[i].gamesEquipo2 == this.configGame)
                              )&&
                              ( (this.datosPartido[i].teabrekEquipo1 >= this.taeGameDif)&&
                                (this.datosPartido[i].teabrekEquipo2 < this.datosPartido[i].teabrekEquipo1 - 1)
                              ) 
                        ){ 
                          //console.log('gana el equipo 1 ');
                          ganaSetsEquipo1++;
                          
                        }else{
                          if  ( ( (this.datosPartido[i].gamesEquipo2 == this.configGame+1)&&
                                  (this.datosPartido[i].gamesEquipo1 == this.configGame)
                                )&&
                                ( (this.datosPartido[i].teabrekEquipo2 >= this.taeGameDif)&&
                                  (this.datosPartido[i].teabrekEquipo1 < this.datosPartido[i].teabrekEquipo2 - 1)
                                ) 
                          ){ 
                            //console.log('gana el equipo 2 ');
                            ganaSetsEquipo2++;
                            
                          }
                        }
                      }
                    }
                  }
                }
              }

            }
          }else{
            //si es desempate
            if (this.taeSetOro > 0){
              //console.log('verifico si gana desempate por Taebreak punto de oro');
              if  ( ( (this.datosPartido[i].gamesEquipo1 == 1)&&
                      (this.datosPartido[i].gamesEquipo2 == 0)
                    )&&
                    ( (this.datosPartido[i].teabrekEquipo1 == this.taeSetOro)&&
                      (this.datosPartido[i].teabrekEquipo2 < this.taeSetOro)
                    ) 
              ){ 
                //console.log('gana el equipo 1 el desempate');
                ganaSetsEquipo1++;
                
              }else{
                if  ( ( (this.datosPartido[i].gamesEquipo2 == 1)&&
                        (this.datosPartido[i].gamesEquipo1 == 0)
                      )&&
                      ( (this.datosPartido[i].teabrekEquipo2 == this.taeSetOro)&&
                        (this.datosPartido[i].teabrekEquipo1 < this.taeSetOro)
                      ) 
                ){ 
                  //console.log('gana el equipo 2 el desempate');
                  ganaSetsEquipo2++;
                  
                }
              }
            }else{
              if (this.taeSetDif > 0){
                //console.log('verifico si gana desempate por Taebreak con diferencia de dos');
                if  ( ( (this.datosPartido[i].gamesEquipo1 == 1)&&
                        (this.datosPartido[i].gamesEquipo2 == 0)
                      )&&
                      ( (this.datosPartido[i].teabrekEquipo1 >= this.taeSetDif)&&
                        (this.datosPartido[i].teabrekEquipo2 < this.datosPartido[i].teabrekEquipo1 - 1)
                      ) 
                ){ 
                  //console.log('gana el equipo 1 el desempate');
                  ganaSetsEquipo1++;
                  
                }else{
                  if  ( ( (this.datosPartido[i].gamesEquipo2 == 1)&&
                          (this.datosPartido[i].gamesEquipo1 == 0)
                        )&&
                        ( (this.datosPartido[i].teabrekEquipo2 >= this.taeSetDif)&&
                          (this.datosPartido[i].teabrekEquipo1 < this.datosPartido[i].teabrekEquipo2 - 1)
                        ) 
                  ){ 
                    //console.log('gana el equipo 2 el desempate');
                    ganaSetsEquipo2++;
                    
                  }
                }
              }
            }
          }
        }  
        if (this.partidoFinalizado == false){
          if ((ganaSetsEquipo1+ganaSetsEquipo2) != (i+1)){
            //console.log('El set nro: '+(i+1)+' no se encuentra terminado.');
            this.salirError = true;
            this.message = 'El set nro: '+(i+1)+' no se encuentra terminado.';
          }else{
            if (ganaSetsEquipo1 == necesitanGanarSets){
              this.partidoFinalizado = true;
              this.equipoGanador = 1;
              this.message= 'ganador: '+this.datosPartido[i].equipo1+ ' en '+this.datosPartido[i].NroSet+ ' Set/s';
            }else{
              if (ganaSetsEquipo2 == necesitanGanarSets){
                this.partidoFinalizado = true;
                this.equipoGanador = 2;
                this.message= 'ganador: '+this.datosPartido[i].equipo2+ ' en '+this.datosPartido[i].NroSet+ ' Set/s';
              }
            }
          }
        }
      }
    }
    // informo el resultado del for
    if (this.partidoFinalizado == true){
      //guardo


      for (let i = 0; i < this.datosPartido.length; i++){
        let body = {
                    "cod_torneo": this.codTorneo,
                    "cod_partido": this.codPartido,
                    "equipo_ganador": this.equipoGanador,
                    "NroSet": this.datosPartido[i].NroSet,
                    "gamesEquipo1": this.datosPartido[i].gamesEquipo1,
                    "gamesEquipo2": this.datosPartido[i].gamesEquipo2,
                    "teabrekEquipo1": this.datosPartido[i].teabrekEquipo1,
                    "teabrekEquipo2": this.datosPartido[i].teabrekEquipo2,
        }
        this._torneosService.torneoGuardarPartido(body,this.token).subscribe(
          response => {
            if(response.status != 'error'){
              //console.log(response);

            }else{
              console.log(response.message);
            }
          },
          error => {
            console.log(error);
          } 
        )
      }




      //console.log(this.message);
      this.guardarProcesando = false;
      this.recargarPartido;
    }else{
      if (this.salirError == false){
        //no hay ganador
        this.message = 'no se pudo guardar por: El partido aun no tiene ganador';
        this.guardarProcesando = false;
      }else{
        //console.log('no se pudo guardar por:  '+this.message);
        this.guardarProcesando = false;
      }
    }
  }

  verificar(){
    if (this.finalizado == false && this.destroy == false && this.editPuntos == false){
      //console.log('sigue aca');
      setTimeout(() => {
          this.recargarPartido()
        }, 10000
      );
    }
  }

}
