import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SiteService }  from 'src/app/services/site.service';
import { UserService }  from 'src/app/services/user.service';
import { TorneosService }  from 'src/app/services/torneos.service';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalService } from 'src/app/services/local.service';
import { DataService } from 'src/app/services/data.service';
import { exit } from 'process';



@Component({
  selector: 'app-torneo-general',
  templateUrl: './verTorneoGeneralMovil1.component.html',
  styleUrls: ['./verTorneoGeneral.component.css'],
  providers: [SiteService, UserService, LocalService, TorneosService]
})
export class VerTorneoGeneralComponent implements OnInit {
  @Input() varempresa: string;
  @Input() codTorneo: number;
  @Input() dataTorneo: any;
  @Input() catDeportes: any;

  loading: boolean;
  subLoading: boolean = false;
  vartoken;
  identity;
  colorSite: string;
  ver;
  zonas;
  tablaZona;
  partidos;
  body;
  
  
  inscriptos;
  
  //errors
  status = ''; 
  message = '';
  columnToSort = '';
  isMovil='N';
  private seter = 0; 

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteServices: SiteService,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _localService: LocalService,
    private _torneosService: TorneosService,
    private dataService: DataService
  ){ 
    
  }
  
  fechaformato(fecha:Date):string {
    return formatDate(fecha,'yyyy/MM/dd HH:mm','en-US');
  }
  tratarDate(fecha:Date):string {
    return formatDate(fecha,'yyyy/MM/dd HH:mm','en-US');
  }

  armarLlaves(){
    //console.log('armar llave');
    //console.log(this.tablaZona.eliminacion);
    //console.log(this.dataTorneo.llave_inicial);
    // crear los partidos vacios y llenar la llave
    let body = {'cod_torneo':this.codTorneo, 'llave_inicial':this.dataTorneo.llave_inicial};
    this._torneosService.torneoArmarPartidosLlaves(body, this.vartoken).subscribe(
      response => {
        //console.log(response);
        if(response.status != 'error'){
          this.cargar();
        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

  mostrar(fase, detFase, ronda = 1){
    //console.log(this.tablaZona);
    if (fase == 'ZONA'){
      this.ver={'zona': detFase, 'itemzona': -1, 'submenu': 'tabla', 'ronda': ronda};
    };
    if (fase == 'ELIM'){
      this.ver={'zona': '', 'submenu': 'partidos', 'ronda': ronda, 'llave': detFase};
      //console.log(this.ver);
    };
    //console.log(this.ver);
    //cargar los partidos de la zona
    this.partidos= [];
    for (let i = 0; i<this.tablaZona.zona.length; i++){
      this.tablaZona.zona[i].rondas = [];
      //console.log(detFase);
      //console.log(this.tablaZona.zona[i].zona);
      if (detFase == this.tablaZona.zona[i].zona){
        this.ver.itemzona = i;
      }
    }
    //console.log(this.ver);
    this.body = {'cod_torneo': this.dataTorneo.cod_torneo};
    //console.log(this.body);
    this._torneosService.torneoPartidos(this.body, '').subscribe(
      response => {
        //console.log(response);
        if(response.status != 'error'){
          this.partidos = response;
          let zona = '';
          let ronda = 0;
          let indicezona=0;
          for (let i = 0; i<this.partidos.length; i++){
            //console.log(i);
            if (this.partidos[i].grupo != null){
              if (this.partidos[i].grupo != zona){
                //console.log(this.tablaZona);
                //buscar la zona
                indicezona=0;
                for(let i1=0; i1<this.tablaZona.zona.length; i1++){
                  if (this.tablaZona.zona[i1].zona == this.partidos[i].grupo){
                    indicezona = i1;
                    zona = this.partidos[i].grupo;
                    ronda = 0;
                    //console.log(indicezona+'    '+zona);
                  }
                }
                
              }else{
                if (this.partidos[i].ronda != ronda){ 
                  this.tablaZona.zona[indicezona].rondas.push({'ronda' : this.partidos[i].ronda});
                  ronda= this.partidos[i].ronda;
                } 
              }  

              
              
            }
            

            //nada aun
          }
          //console.log(this.tablaZona);
        }else{
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

  cargar(){
    this.tablaZona= [];
    //console.log(this.dataTorneo);
    let body = {'cod_torneo': this.dataTorneo.cod_torneo};
    this._torneosService.torneoGetZonas(body, '').subscribe(
      response => {
       // console.log(response);
        if(response.status != 'error'){
          this.zonas = response;
          this.tablaZona = {'zona': [], 'eliminacion': []};
          for (let i = 0; i<this.zonas.length; i++){
            let pasozona = {idzona: i, zona: this.zonas[i].zona, rondas:[]}
            this.tablaZona.zona.push(pasozona);
          }
          let fase = 0;
          
          for (let i=this.dataTorneo.llave_inicial; i>=1; i=(i/2)){
            let texto = '';

            if (i > 8){
              texto = 'Fase '+fase;
            }else{
              if (i == 8){
                texto = 'Octavos';
              }else{
                if (i == 4){
                  texto = 'Cuartos';
                }else{
                  if (i == 2){
                    texto = 'Semifinal';
                  }else{
                    if (i == 1){
                      texto = 'FINAL';
                    }else{
                      ''
                    }
                  }
                }
              }
            }
            
            let vardata = {'idllave':fase, 'llave': i, 'texto':texto};
            this.tablaZona.eliminacion.push(vardata);
            fase++;
          }
          if (!this.ver){
            this.mostrar('ZONA',this.zonas[0].zona);
            //this.ver = {'zona': this.zonas[0].zona, 'submenu': 'tabla'};
            
          }
          this.status = response.status;
          this.message = response.message;
        }
      },
      error => {
        console.log(error);
      } 
    )
  }

  sort(column: string) {
    if (this.columnToSort === column) {
      this.inscriptos.reverse();
    } else {
      this.columnToSort = column;
      this.inscriptos.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
    }
  }

  salir(){
    this.dataService.setNoticiasNav('');
  }

  ngOnInit(): void {
    this.status = ''; 
    this.message = '';
    this.isMovil = localStorage.getItem('navi');
    //console.log(this.catDeportes);
    this.identity = this._siteServices.getIdentity();
    this.seter = Number(localStorage.getItem('seter'));
    //console.log(this.identity);
    this.colorSite = this._siteServices.getColorSite();
    this.vartoken = this._userService.getToken();
    this.cargar();
  }

  
}

